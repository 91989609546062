// Assuming this is the interface which backend will provide in future.
// Will build domain tracker table based on this interface.
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {TableMetadataInterface} from "src/interfaces/server-side-table";
import {ReactNode} from "react";

// Restart execution
export interface SelectedItemsInterface {
    selectedItems: Array<SMSWOUnifiedDashboardServiceLambda.Types.Details>
    tableMetadata: TableMetadataInterface
}

export interface EditSelectedItemsInterface extends SelectedItemsInterface {
    bubbleEdit: boolean,
    modalVisible: boolean,
    visibleColumnKey?: string, // All editable columns will be visible if it's not given.
    handleModalVisibleChange: Function,
}

// Table type to decide header, list card name and other details.
export enum TableTypes {
    EXECUTION_LIST = "EXECUTION_LIST",
    EXECUTION_ATTEMPTS = "EXECUTION_ATTEMPTS",
    VIRTUAL_PIPELINE = "VIRTUAL_PIPELINE",
    EXECUTION_SUMMARY = "EXECUTION_SUMMARY"
}

// Quick overview modal interface
export interface QuickOverviewModalInterface {
    visible: boolean
    handleViewDetailsModalClose: any
    selectedItem: SMSWOUnifiedDashboardServiceLambda.Types.Details
    tableMetadata: TableMetadataInterface
}

export interface WorkflowInstanceRestartConfigOverrideI extends SelectedItemsInterface{
    onRestartSubmitButton: any
    children: ReactNode
    modalVisible: boolean,
    submitFormRef: any
}

