import React, {useEffect, useState} from "react"
import {AppRoutes, ButtonWithRouter} from "src/components/common/router-common";
import {Alert, Box, Grid, Header, Spinner} from "@amzn/awsui-components-react-v3";
import {ExecutionDetailContentI} from "src/interfaces/workflow-instance-detail";
import {
    StageInstances,
    WorkflowInstance
} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";
import {WorkflowDetailSubStagesCards} from "src/components/execution-detail/workflow-detail-sub-stages-cards";
import {WorkflowInstanceOverview} from "src/components/execution-detail/workflow-instance-overview";
import {DomainTracker} from "src/model/DomainTracker";
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";

export function WorkflowDetailContent(props: ExecutionDetailContentI) {
    const [maxVisibleColumns, setMaxVisibleColumns] = useState<Array<number>>([1])
    const [selectedIndex, setSelectedIndex] = useState<Array<number>>([])
    const [stageInstance, setStageInstance] = useState<StageInstances>([])
    const [workflowInstance, setWorkflowInstance] = useState<WorkflowInstance | undefined>()
    const [workflowInstanceError, setWorkflowInstanceError] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)

    function handleStageSelectionChange(stageInstances: StageInstances, currentColumn: number, currentSelectedIndex: number) {
        const maxVisibleColumnsUpdated: Array<number> = []
        SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.showSubStageCard, metricPageNames.workflowDetailView)

        for (let column = 0; column <= currentColumn; column++) {
            maxVisibleColumnsUpdated.push(column + 1);
        }

        const selectedIndexUpdated: Array<number> = JSON.parse(JSON.stringify(selectedIndex))
        selectedIndexUpdated[currentColumn - 1] = currentSelectedIndex
        setSelectedIndex(selectedIndexUpdated)
        setMaxVisibleColumns(maxVisibleColumnsUpdated)
    }

    useEffect(() => {
        setLoading(true)
        setWorkflowInstanceError("")
        const domainTracker = new DomainTracker()
        domainTracker.getWorkflowInstanceDetail(props.workflowId)
            .then(resp => {
                setStageInstance(resp.stageInstances || [])
                setWorkflowInstance(resp.workflowInstance)
            })
            .catch(error => {
                if (error.message != undefined) {
                    setWorkflowInstanceError(error.message)
                } else {
                    setWorkflowInstanceError(JSON.stringify(error))
                }

            })
            .then(() => {
                setLoading(false)
            })


    }, [])

    if (loading) {
        return <Spinner size={"big"}/>
    }
    if (workflowInstanceError != "") {
        return <Alert type={"error"}>{workflowInstanceError}</Alert>
    }


    return <div>

        <Header variant={"h1"}
                actions={<ButtonWithRouter text="View attempts"
                                           href={`${AppRoutes.executionAttempts(props.workflowId)}`}/>}>
            Workflow id: {props.workflowId}
        </Header>

        <WorkflowInstanceOverview workflowInstance={workflowInstance} workflowId={props.workflowId}/>
        <hr/>
        <Header variant="h1">Stage details</Header>
        <Grid
            gridDefinition={[
                {colspan: {default: 3, xxs: 3}},
                {colspan: {default: 3, xxs: 3}},
                {colspan: {default: 3, xxs: 3}},
                {colspan: {default: 3, xxs: 3}}
            ]}
        >

            {maxVisibleColumns.map(currentColumn => {
                let stageInstancesUpdated: StageInstances = JSON.parse(JSON.stringify(stageInstance))
                for (let column = 1; column < currentColumn; column++) {
                    stageInstancesUpdated = stageInstancesUpdated[selectedIndex[column - 1]].subStageInstances || []
                }

                return <Box padding={{right: "l"}}
                >
                    <WorkflowDetailSubStagesCards stageInstances={stageInstancesUpdated}
                                                  handleSelectionChange={handleStageSelectionChange}
                                                  columnNumber={currentColumn}
                                                  key={`column-${currentColumn}`}
                    />
                </Box>
            })}

        </Grid>


    </div>
}