export const metricNamespace: string = "SMSWOUnifiedDashboardWebsite"
export const metricPageNames = {
    workflowInstanceListView: "workflowInstanceListView",
    workflowDetailView: "workflowDetailView",
    workflowAttemptsView: "workflowAttemptsView",
    workflowAggregateInstanceView: "workflowAggregateInstanceView",
    homePage: "homePage"
}
export const metricFeaturesList = {
    terminateWorkflowInstance: "terminateWorkflowInstance",
    bulkTerminateWorkflowInstance: "bulkTerminateWorkflowInstance",
    restartWorkflowInstance: "restartWorkflowInstance",
    bulkRestartWorkflowInstance: "bulkRestartWorkflowInstance",
    observeWorkflowInstance: "observeWorkflowInstance",
    bulkObserveWorkflowInstance: "bulkObserveWorkflowInstance",
    redirectURL: "redirectURL",
    outputWorkitem: "outputWorkitem",
    showSubStage: "showSubStage",
    showSubStageCard: "showSubStageCard",
    showEventsPopup: "showEventsPopup",
    index: "index"
}

export const additionalAttributes = {
    isRestartOverrideInput: "isRestartOverrideInput"
}