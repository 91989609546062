/* istanbul ignore file */
import KatalLogger, { Level } from "@katal/logger";

const katalLoggerConfig = {
    url: "{{KatalLoggerUrl}}",
    logThreshold: Level.INFO,
    maxLogLineSize: 10000,
    logToConsole: false
};

export const logger = new KatalLogger(katalLoggerConfig);
logger.addErrorListener();