import React from "react"
import {useCollection} from "@amzn/awsui-collection-hooks";
import {useState} from "react";
import {
    DEFAULT_PREFERENCES,
    COLUMN_DEFINITIONS,
    Preferences, VirtualPipelineDetails
} from "src/components/virtual-pipeline/virtual-pipeline-list-table-config";
import {DateRangePicker, Pagination, Table, TextFilter} from "@amzn/awsui-components-react-v3";
import {
    virtualPipelineSelectionLabels,
    paginationLabels
} from "src/components/virtual-pipeline/virtual-pipeline-list-labels";
import {VirtualPipelineListTableHeader} from "src/components/virtual-pipeline/common-components";
import {TableNoMatchState} from "src/components/common/table-common-components";
import {
    convertToAbsoluteRange, dateRangeFilterI18nStrings,
    isValidRangeFunction
} from "src/components/virtual-pipeline/virtual-pipeline-instance/dashboard-utils";
import {isWithinInterval} from "date-fns";
import {DateRangePickerProps} from "@amzn/awsui-components-react-v3/uxdg";
import Moment from "moment";

/**
 * Generic table component. Use API call to get all virtual pipelines, and table in polaris 3.0 will handle filter
 * pagination and sorting. The column definition is in the virtual-pipeline-list-labels.
 * @constructor
 */
export function VirtualPipelineListTable(props: {instances: VirtualPipelineDetails[], isLoaded: boolean}) {
    const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
    const [isFirstSearch, setIsFirstSearch] = useState(true)
    const [rangeFilter, setRangeFilter] = useState<DateRangePickerProps.Value>({
        type: "relative",
        amount: 1,
        unit: "year"
    })
    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        props.instances,
        {
            filtering: {
                empty: <TableNoMatchState/>,
                noMatch: <TableNoMatchState/>,
                filteringFunction: (item, filteringText) => {
                    if (rangeFilter !== null) {
                        const range = convertToAbsoluteRange(rangeFilter);
                        if (!isWithinInterval(new Date(item.creationDate), range)) {
                            return false
                        }
                    }

                    const filteringTextLowerCase = filteringText.toLowerCase();

                    return COLUMN_DEFINITIONS.map(key => {
                        let value = item[key.id]
                        switch (key.id) {
                            case "creationDate": case "lastUpdateDate":
                                value = Moment(item[key.id]).format('YYYY-MM-DD HH:MM:SS');
                                break;
                        }
                        return value
                    }).some(
                        value => typeof value === 'string' && value.toLowerCase().indexOf(filteringTextLowerCase) > -1
                    );
                }
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {}
        }
    );

    const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

    return (
        <Table
            {...collectionProps}
            columnDefinitions={COLUMN_DEFINITIONS}
            loadingText={"Loading data..."}
            loading={props.isLoaded}
            visibleColumns={preferences.visibleContent}
            items={items}
            selectionType={"single"}
            ariaLabels={virtualPipelineSelectionLabels}
            stickyHeader={true}
            resizableColumns={true}
            wrapLines={preferences.wrapLines}
            header={<VirtualPipelineListTableHeader
                selectedItems={collectionProps.selectedItems!.concat()}
                totalItems={props.instances}
                totalItemCount={props.instances.length}
            />}
            empty={<TableNoMatchState/>}
            filter={
                <div className="filter-container">
                    <div className="input-filter">
                        <TextFilter
                            {...filterProps}
                            disabled={props.isLoaded}
                            filteringAriaLabel="Filter virtual pipelines"
                            filteringPlaceholder="Find virtual pipelines"
                        />
                    </div>
                    <DateRangePicker
                        id={"virtual-pipeline-list-time-select-date-range-picker"}
                        className="date-range-filter"
                        disabled={props.isLoaded}
                        onChange={({ detail }) => {
                            setIsFirstSearch(false)
                            setRangeFilter(detail.value!)
                        }}
                        value={rangeFilter}
                        placeholder="Filter by a date and time range"
                        relativeOptions={[
                            {
                                type: 'relative',
                                amount: 1,
                                unit: 'month',
                                key: 'one-month'
                            },
                            {
                                type: 'relative',
                                amount: 3,
                                unit: 'month',
                                key: 'three-months'
                            },
                            {
                                type: 'relative',
                                amount: 6,
                                unit: 'month',
                                key: 'six-months'
                            },
                            {
                                type: 'relative',
                                amount: 1,
                                unit: 'year',
                                key: 'one-year'
                            }
                        ]}
                        isValidRange={isValidRangeFunction}
                        i18nStrings={dateRangeFilterI18nStrings}
                    />

                    {(filterProps.filteringText || rangeFilter && !isFirstSearch) && (
                        <span className="filtering-results">{getFilterCounterText(filteredItemsCount!)}</span>
                    )}
                </div>
            }
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} disabled={props.isLoaded}/>}
            preferences={<Preferences preferences={preferences} setPreferences={setPreferences} disabled={props.isLoaded}/>}
        />
    )
}