import React, {useEffect, useState} from 'react';
import AppLayout from '@amzn/awsui-components-react-v3/polaris/app-layout';
import HomepageContent from './HomepageContent';
import { ServiceNavigation } from '../navigation';
import { appLayoutNavigationLabels } from 'src/common/labels';
import './styles.scss';
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";

export default function ServiceHomepage() {
  const [navigationOpen, setNavigationOpen] = useState(false);
  useEffect(() => {
    SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.index, metricPageNames.homePage)
  })
  return (
    <AppLayout
      navigation={<ServiceNavigation />}
      content={<HomepageContent />}
      contentType="default"
      disableContentPaddings={true}
      navigationOpen={navigationOpen}
      onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
      toolsHide={true}
      ariaLabels={appLayoutNavigationLabels}
    />
  );
}