import AWS, {Credentials} from 'aws-sdk';
import {ListExecutionsOutput, StartExecutionOutput} from "aws-sdk/clients/stepfunctions";
import {wimAPIConfig} from "src/utils/api-gateway/mappings";
import {getStage} from "src/utils/env";

export class AwsStepFnUtil {
    readonly stateMachineArn = "arn:aws:states:us-west-2:795619029800:stateMachine:SMPromiseManagerHack_1"
    sfn: AWS.StepFunctions
    sts: AWS.STS
    region: string
    credentials: Credentials

    constructor() {
        this.sts = new AWS.STS()
        this.region = wimAPIConfig(getStage()).region
        this.credentials = new Credentials({accessKeyId: "", secretAccessKey: ""})

        this.sfn = new AWS.StepFunctions({
            credentials: this.credentials,
            region: this.region
        })

    }

    async statSFNExecution(): Promise<StartExecutionOutput> {
        await this.getTempCredentials()
            .then(resp => {
                this.credentials = resp;
            })
        const startExecutionResp = await new AWS.StepFunctions({
            credentials: this.credentials,
            region: this.region
        }).startExecution({
            stateMachineArn: this.stateMachineArn,
            input: JSON.stringify({expirydate: 20})
        }).promise()

        return startExecutionResp;
    }

    async getAllSFNExecutions(): Promise<ListExecutionsOutput> {
        await this.getTempCredentials()
            .then(resp => {
                this.credentials = resp;
            })

        const listExecutionsResp = await new AWS.StepFunctions({
            credentials: this.credentials,
            region: this.region
        }).listExecutions({
            stateMachineArn: this.stateMachineArn
        }).promise()
        return listExecutionsResp;

    }

    async getTempCredentials(): Promise<Credentials> {
        const assumeRoleResp = await this.sts.assumeRole({
            RoleSessionName: "aravip",
            RoleArn: "arn:aws:iam::795619029800:role/service-role/StepFunctions-SWO-E2E-Integ-Aravip-role-320425cd"
        })
            .promise()

        if (assumeRoleResp.Credentials != undefined) {
            const iamCredentials = assumeRoleResp.Credentials
            const credentials = new Credentials({
                accessKeyId: iamCredentials.AccessKeyId,
                secretAccessKey: iamCredentials.SecretAccessKey,
                sessionToken: iamCredentials.SessionToken
            })

            return credentials
        }

        return Promise.reject("failed to retrieve temp credentials")

    }
}