import React, {useEffect, useState} from "react"
import {Input, Select, SelectProps, Spinner} from "@amzn/awsui-components-react-v3";
import {DomainTracker} from "src/model/DomainTracker";
import {ES_SEARCH_TYPES} from "src/constants/elastic-search";
import {EditingFormFieldI} from "src/interfaces/server-side-table";

/**
 * Decides input type based on table metadata
 * @param props
 * @constructor
 */
export function EditingFormField(props: EditingFormFieldI): JSX.Element {
    let inputFieldElement: JSX.Element = <></>;
    const [dropDownLoading, setDropDownLoading] = useState<boolean>(false)
    const [dropDownValues, setDropDownValues] = useState<Array<string>>([])

    useEffect(() => {
        if ((props.columnDefinition?.dataType == ES_SEARCH_TYPES.KEYWORD) && props.modalVisible) {
            setDropDownLoading(true)
            setDropDownValues([])
            const attributeValues = DomainTracker.searchAttributeValuesByKeyAsync(props.columnKey || "")
            attributeValues.then((resp) => {
                setDropDownValues(resp)
            }).catch((error) => {
                setDropDownValues([])
            }).then(() => setDropDownLoading(false))
        }

    }, [props.columnKey, props.columnDefinition, props.modalVisible])


    switch (props.columnDefinition?.dataType) {
        case ES_SEARCH_TYPES.KEYWORD:
        case ES_SEARCH_TYPES.TEXT:
            if (dropDownValues.length > 0) {
                const dropDownOptions: Array<SelectProps.Option> = [...dropDownValues.map(dropDownValue => {
                    return {
                        label: dropDownValue,
                        value: dropDownValue
                    }
                })]

                const selectedOption: SelectProps.Option | null = dropDownOptions.find(dropDownValue => dropDownValue.value == props.value) || null
                inputFieldElement = <>
                    <Select options={dropDownOptions}
                            selectedOption={selectedOption}
                            onChange={(e) =>
                                props.handleValueChange(e.detail.selectedOption.value, props.columnKey)}/>
                </>
            } else {
                inputFieldElement = <Input
                    value={props.value}
                    onChange={(e) => props.handleValueChange(e.detail.value, props.columnKey)}
                    placeholder={`${props.columnKey} value`}/>
            }

            if (dropDownLoading) {
                inputFieldElement = <Spinner size="normal"/>
            }
            break;
        default:
            inputFieldElement = <>Edit option is not available</>
            break;
    }
    return <>
        {inputFieldElement}
    </>
}