import React from "react";
import {AxiosError} from "axios";

interface ErrorMessageHelperI {
    error: AxiosError<any>
}

/**
 * This will format APIGW error response and display proper error message.
 * @param props - APIGW error response
 * @constructor
 */
export function ErrorMessageHelper(props: ErrorMessageHelperI) {
    return <>
        {props.error.message}
    </>
}