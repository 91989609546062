// import the token here to test that our jest-preset handles it
import * as tokens from "@amzn/awsui-design-tokens";
import React from "react";
import {Box, ColumnLayout, Container, Grid, Header, Link, SpaceBetween} from '@amzn/awsui-components-react-v3';
import {AppRoutes, ButtonWithRouter, ExternalRoutes, RouteLabels} from "src/components/common/router-common";
import {isProd} from "src/utils/env";
import {ReleaseNotesContent} from "src/components/release-notes/content";

// The content in the main content area of the App layout
export default function HomepageContent() {
    return (
        <div>
            <div style={{background: tokens.colorBackgroundHomeHeader}} className={"custom-home__header"}>
                <Box padding={{vertical: "xxl", horizontal: "s"}}>
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 8, xxs: 10}, offset: {l: 2, xxs: 1}},
                            {colspan: {l: 5, s: 6, xxs: 10}, offset: {l: 2, xxs: 1}},
                            {colspan: {l: 3, s: 4, xxs: 10}, offset: {s: 0, xxs: 1}},
                        ]}
                    >
                        <Box padding={{top: "xs"}} fontWeight="light">
                            <span className="custom-home-text-inverted">SMT</span>
                        </Box>
                        <div className="custom-home-text-inverted">
                            <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
                                SWO UI
                            </Box>
                            <Box fontWeight="light" padding={{bottom: "s"}} fontSize="display-l" color="inherit">
                                System Workflow Orchestrator
                            </Box>
                            <Box variant="p" fontWeight="light">
                                <span className="custom-home-text-secondary">
                  SWO translates a business intent of retrieving external selection information into a series of steps
                  involving SM systems and manual tasks
                </span>
                            </Box>
                        </div>
                    </Grid>
                </Box>
            </div>

            <Box margin={{top: 's'}} padding={{top: 'xxl', horizontal: 's'}}>
                <Grid
                    gridDefinition={[
                        {colspan: {xl: 6, l: 8, s: 8, xxs: 10}, offset: {l: 2, xxs: 1}},

                    ]}
                >
                    <div className="custom-home-main-content-area">
                        <SpaceBetween size="l">
                            <div>
                                <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
                                    Getting started
                                </Box>
                                <Container>
                                    <ColumnLayout columns={2} variant="text-grid">
                                        <Box variant={"div"}>
                                            <Box variant="h3" padding={{top: 'n'}}>
                                                Executions
                                            </Box>
                                            <Box variant="p">
                                                View, add comments, and restart your executions with a few simple
                                                clicks.
                                            </Box>
                                            <Box variant="div" padding={{bottom: 'xs'}}>
                                                <ButtonWithRouter href={AppRoutes.executionList}
                                                                  text={RouteLabels.workflowInstanceList}/>

                                            </Box>
                                            <Box variant="div" padding={{bottom: 'xs'}}>
                                                <ButtonWithRouter href={AppRoutes.executionSummary}
                                                                  text={RouteLabels.workflowInstanceSummary}/>
                                            </Box>

                                            <Box variant="div">
                                                <ButtonWithRouter href={ExternalRoutes.acbpAutomatedPipelineSummary}
                                                                  text={RouteLabels.acbpAutomatedPipelineSummary}
                                                                  icon={"external"}
                                                                  iconAlign={"right"}
                                                                  disabled={!isProd()}/>
                                            </Box>

                                        </Box>
                                        <div>
                                            <Box variant="h3" padding={{top: 'n'}}>
                                                Workitem summary
                                            </Box>
                                            <Box variant="p">
                                                Work Item Management Service enables SM to shift to React on Request
                                                model. It provides a unified interface to assign a manual task to SMPO
                                                workforce and get notified whenever it’s marked as completed by the
                                                assignee.
                                            </Box>
                                            <Box variant="div" padding={{bottom: 'xs'}}>
                                            <ButtonWithRouter href={ExternalRoutes.e2ePipelineWorkitemSummary}
                                                              text={RouteLabels.e2ePipelineWorkitemSummary} icon={"external"}
                                                              iconAlign={"right"}
                                                              disabled={!isProd()}/>
                                            </Box>
                                        </div>

                                        <div>
                                            <Box variant="h3" padding={{top: 'n'}}>
                                                Cycle time dashboard
                                            </Box>
                                            <Box variant="p">
                                                Dashboards and reports to track cycle time at stage and sub stage level
                                            </Box>
                                            <Box variant="div" padding={{bottom: 'xs'}}>
                                            <ButtonWithRouter href={ExternalRoutes.stageLevelCycleTimeDashboard}
                                                              text={RouteLabels.stageLevelCycleTimeDashboard} icon={"external"}
                                                              iconAlign={"right"}
                                                              disabled={!isProd()}/>
                                            </Box>

                                            <ButtonWithRouter href={ExternalRoutes.subStageLevelCycleTimeDashboard}
                                                              text={RouteLabels.subStageLevelCycleTimeDashboard} icon={"external"}
                                                              iconAlign={"right"}
                                                              disabled={!isProd()}/>
                                        </div>

                                    </ColumnLayout>
                                </Container>
                            </div>

                            <div>
                                <Container header={<Header variant="h2">User guides</Header>}>
                                    <ColumnLayout columns={2} variant="text-grid">
                                        <div>
                                            <Box variant="h3" padding={{top: 'n'}}>
                                                <Link fontSize="heading-m"
                                                      href="https://quip-amazon.com/rsp8ARavSMzX/User-Guide-for-Downloading-SWO-Recipe-Executions-View"
                                                      external={true}>
                                                    Downloading SWO Recipe Executions View.
                                                </Link>
                                            </Box>
                                            <Box variant="p">
                                                SWO provides execution list view with various filters. However, there is
                                                no option to download it in real time. Please follow the guide to
                                                download data from s3 in CSV format.
                                            </Box>
                                        </div>
                                        <div>
                                            <Box variant="h3" padding={{top: 'n'}}>
                                                <Link fontSize="heading-m"
                                                      href={`${ExternalRoutes.swoDashboardUserGuide}`}
                                                      external={true}>
                                                    SWO dashboard user guide
                                                </Link>
                                            </Box>
                                            <Box variant="p">
                                                This document provides an introduction to SWO Dashboard and WorkItems for automated e2e pipeline.
                                            </Box>
                                        </div>

                                    </ColumnLayout>
                                </Container>
                            </div>

                            <div>
                            <ReleaseNotesContent numberOfNotes={2}/>
                                {/*TODO: Once we've planned release, we'll add this again.*/}
                                {/*<Container>*/}
                                {/*    <Box variant={"div"} textAlign={"center"}>*/}
                                {/*        <ButtonWithRouter text={"View all"} href={AppRoutes.releaseNotes}> </ButtonWithRouter>*/}
                                {/*    </Box>*/}
                                {/*</Container>*/}
                            </div>
                        </SpaceBetween>
                    </div>

                </Grid>
            </Box>

        </div>
    );
}
