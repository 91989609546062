import React, {useEffect, useState} from "react";
import Moment from "moment"
import {Alert, Box, Container, Grid, Header} from "@amzn/awsui-components-react-v3";
import InstanceOverview from "src/components/virtual-pipeline/virtual-pipeline-instance/instance-overview";
import TimeRangeSelect from "src/components/virtual-pipeline/virtual-pipeline-instance/time-range-select";
import EndToEndLatency from "src/components/virtual-pipeline/virtual-pipeline-instance/end-to-end-latency";
import {VirtualPipelineInstanceTable} from "src/components/virtual-pipeline/virtual-pipeline-instance/instance-table/virtual-pipeline-instance-table";
import {useApiRequestVPInfo, useApiRequestVPInstance} from "src/hooks/vp-table-hooks";
import {
    VIRTUAL_PIPELINE_INST_SAMPLE,
    VIRTUAL_PIPELINE_SAMPLE,
    VirtualPipelineVisualization
} from "src/constants/virtual-pipelines";
import {
    convertToAbsoluteRange,
    PageHeader
} from "src/components/virtual-pipeline/virtual-pipeline-instance/dashboard-utils";
import {ButtonWithRouter} from "src/components/virtual-pipeline/common-components";
import {AppRoutes} from "src/components/common/router-common";
import {DateRangePickerProps} from "@amzn/awsui-components-react-v3/uxdg";
import {VirtualPipelineInstanceIdleOperationDashboard} from "src/components/virtual-pipeline/virtual-pipeline-instance/idle-operation-latency/virtual-pipeline-instance-idle-operation-dashboard";
import {DatasetInstanceNode, DatasetNode} from "@amzn/sm-virtual-pipeline-lambda-js-client/lib/smvirtualpipelinelambda";

/**
 * Virtual Pipeline Instance dashboard content. Getting vpId from the table.
 * @param props Contains vpId.
 * @constructor
 */
export function VirtualPipelineInstanceDashboard(props: { vpId: string }) {
    const defaultDateRange = convertToAbsoluteRange({
        type: "relative",
        amount: 1,
        unit: "month",
        key: 'one-month'
    })

    const [timeInterval, setTimeInterval] = useState<{ startTime: number, endTime: number }>({
        startTime: defaultDateRange.start.getTime(),
        endTime: defaultDateRange.end.getTime()
    })

    const [dateRangeFilter, setDateRangeFilter] = useState<DateRangePickerProps.Value>({
            type: "relative",
            amount: 1,
            unit: "month"
        }
    )

    const vpId = props.vpId

    const {
        infoError,
        vp,
        isVpLoaded
    } = useApiRequestVPInfo(vpId)

    const {
        error,
        instances,
        isLoaded
    } = useApiRequestVPInstance(vpId, timeInterval)

    const vpName = vp["vpName"]
    const isLoading = !isVpLoaded && !isLoaded

    function dateChangeHandler(startDate: string, endDate: string, rangeFilter: DateRangePickerProps.Value) {
        setDateRangeFilter(rangeFilter)
        setTimeInterval({
            startTime: new Date(Date.parse(startDate)).getTime(),
            endTime: new Date(Date.parse(endDate)).getTime()
        })
    }

    const gridDefinition = [
        { colspan: { l: 5, m: 5, default: 12 } },
        { colspan: { l: 7, m: 7, default: 12 } },
        { colspan: { l: 12, m: 12, default: 12 } },
        { colspan: { l: 12, m: 12, default: 12 } },
        { colspan: { l: 12, m: 12, default: 12 } }
    ]
    return (
        <>
            <Alert type={"error"} visible={infoError != ""}> {infoError}</Alert>
            <Alert type={"error"} visible={error != ""}> {error}</Alert>
            <PageHeader vpId={vpId} vpName={vpName} isLoading={!isLoading}/>
            <Grid
                gridDefinition={gridDefinition}
            >
                <TimeRangeSelect
                    dateRange={dateRangeFilter}
                    dateChangeHandler={dateChangeHandler}
                    isLoading={!isLoading}
                />
                <InstanceOverview
                    instances={instances}
                    date={{
                        startDate: Moment(new Date(timeInterval.startTime)).format('YYYY-MM-DD'),
                        endDate: Moment(new Date(timeInterval.endTime)).format('YYYY-MM-DD')
                    }}
                    isLoading={!isLoading}
                />
                <EndToEndLatency
                    instances={instances}
                    isLoading={!isLoading}
                />
                <VirtualPipelineInstanceIdleOperationDashboard
                    instances={instances}
                    pipelineInfo={vp.pipelineInformation}
                    isLoading={!isLoading}
                />
                <VirtualPipelineInstanceTable
                    instances={instances}
                    isLoading={!isLoading}
                />

            </Grid>
        </>
    )
}