import axios, {AxiosResponse} from "axios";
import {apiGatewayPaths} from "src/utils/api-gateway/mappings";
import {MidwayAuth} from "src/utils/auth/midway-auth";
import {GetWFAlertsRespI} from "src/interfaces/promise-manager-interface";
import {logger} from "src/utils/logger";

export class PromiseManager {
    readonly apiGWUrl = `https://0nwukxh3p6.execute-api.us-west-2.amazonaws.com/alpha/monitorWorkflow`
    readonly apiGWhostName = `0nwukxh3p6.execute-api.us-west-2.amazonaws.com`
    readonly apiGWRegion = `us-west-2`

    async getWFAlerts(): Promise<AxiosResponse<GetWFAlertsRespI>> {
        // const path = `/${this.apiConfig.stage}${apiGatewayPaths.getWorkitem.path}/`;
        const path = `/alpha/monitorWorkflow`;
        const request = {
            hostname: this.apiGWhostName,
            method: "GET",
            url: this.apiGWUrl,
            path: path,
        };

        const signedRequest = await MidwayAuth.signRequest(request);
        const resp: Promise<AxiosResponse<GetWFAlertsRespI>> = axios.request(signedRequest)
        return resp;
    }

    async monitorWf(workflowInstanceId: string) {
        const path = `/alpha/monitorWorkflow`;
        const body = {workflowInstanceId:workflowInstanceId}
        ;
        logger.info(`workflowInstanceId ${workflowInstanceId} `, body)
        const request = {
            host: this.apiGWhostName,
            url: this.apiGWUrl,
            data: body,
            body: JSON.stringify(body),
            method: "POST",
            service: 'execute-api',
            path: path,
            region: this.apiGWRegion,
            headers: {
                "Content-Type": "application/x-amz-json-1.1",
            },
        };
        const signedRequest = await MidwayAuth.signRequest(request);
        return axios.request(signedRequest);
    }

}