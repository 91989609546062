import "@amzn/awsui-global-styles/polaris.css";
import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {PageNotFound} from "src/components/errors/page-not-found";
import WIMOutput from "src/components/wim-output/index";
import ServiceHomepage from "src/components/home";
import DomainTracker from "src/components/domain-tracker"
import '@amzn/awsui-components-react/index.css';
import {Authentication} from "src/components/Authentication";
import ExecutionAttemptsPage from "src/components/execution-attempts";
import {AppRouteParams, AppRoutes} from "src/components/common/router-common";
import ExecutionDetailPage from "src/components/execution-detail";
import VirtualPipeline from "src/components/virtual-pipeline";
import {ExecutionSummary} from "src/components/execution-summary";
import {EsQueryConverter} from "src/components/filters/es-query-converter";
import {ExecutionSummaryRedirectPage} from "src/components/execution-summary/redirect";
import VirtualPipelineVisualization from "src/components/virtual-pipeline/virtual-pipeline-visualization";
import VirtualPipelineInstance from "src/components/virtual-pipeline/virtual-pipeline-instance";
import {ReleaseNotes} from "src/components/release-notes";
import {BulkOnboarding} from "src/components/bulk-onboarding";
import {PromiseDetails} from "src/components/promise-details";


export class App extends React.Component {

    render() {
        return (
            <>
                <Authentication>
                    <div className="awsui">
                        <BrowserRouter>
                            {/* Use HashRouter instead of BrowserRouter in development */}
                            {/*<HashRouter>*/}
                            <Switch>
                                <Route exact path={AppRoutes.home} component={ServiceHomepage}/>
                                <Route exact path={AppRoutes.wimOutput(`:${AppRouteParams.wimOutput.workitemId}`)}
                                       component={WIMOutput}/>
                                <Route exact path="/ud/execution-list" component={DomainTracker}>
                                    <Redirect to={AppRoutes.executionList}/>
                                </Route>
                                <Route exact path={AppRoutes.executionList} component={DomainTracker}/>
                                <Route exact
                                       path={AppRoutes.executionDetail(`:${AppRouteParams.executionDetail.workflowId}`)}
                                       component={ExecutionDetailPage}/>
                                <Route exact
                                       path={"/promise-manager"}
                                       component={PromiseDetails}/>
                                <Route exact
                                       path={AppRoutes.executionAttempts(`:${AppRouteParams.executionDetail.workflowId}`)}
                                       component={ExecutionAttemptsPage}/>
                                <Route exact
                                path={AppRoutes.executionSummary}
                                component={ExecutionSummary}/>
                                <Route exact
                                       path={AppRoutes.releaseNotes}
                                       component={ReleaseNotes}/>
                                <Route exact path={AppRoutes.virtualPipelines} component={VirtualPipeline}/>
                                <Route exact path={`${AppRoutes.virtualPipelines}/instance/:vpId`} component={VirtualPipelineInstance}/>
                                <Route exact path={`${AppRoutes.virtualPipelines}/visualization/:vpId`} component={VirtualPipelineVisualization}/>

                                <Route exact
                                       path={AppRoutes.executionSummaryRedirect}
                                       component={ExecutionSummaryRedirectPage}/>
                                <Route exact
                                       path={"/es-query-converter"}
                                       component={EsQueryConverter}/>
                                <Route exact
                                       path={`${AppRoutes.bulkOnboarding}`}
                                       component={BulkOnboarding}/>
                                <Route path="*" component={PageNotFound}/>
                            </Switch>
                            {/*</HashRouter>*/}
                        </BrowserRouter>
                    </div>
                </Authentication>
                <div>
                    <ToastContainer className="custom-toast" position={toast.POSITION.TOP_CENTER}
                                    style={{width: "500px"}}/>
                </div>
            </>
        );
    }
}

export default App;
