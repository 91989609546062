import React, {useEffect, useState} from "react"
import {TableNoMatchState} from "src/components/common/table-common-components";
import {ServerSideTableHeader} from "src/components/domain-tracker/common-components";
import {Alert, Pagination, Table} from "@amzn/awsui-components-react-v3";
import {TableProps} from "@amzn/awsui-components-react-v3/polaris/table";
import {columnDefinitionsV3, getVisibleColumns, getVisibleContentOptions} from "src/components/table/table-config";
import {ServerSideTableV3I} from "src/interfaces/server-side-table-v3";
import {useApiRequestV3} from "src/hooks/table-hooks";
import {getPagesCount} from "src/utils/table-helper";
import {DEFAULT_TABLE_PREFERENCE, UserTablePreferences} from "src/components/common/table-user-preferences";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {KeyValuePairString} from "src/interfaces/common";
import {Filters} from "src/components/filters";
import {UrlHelper} from "src/utils/url-helper";
import {CollectionPreferencesProps} from "@amzn/awsui-components-react-v3/polaris/collection-preferences/interfaces";
import {Preferences} from "src/components/table/table-preferences";
import {AttributeFilterItemsI, EsQueryTableFilterI} from "src/interfaces/attribute-filters";

/**
 * Server side table using Polaris Table V3.
 * https://polaris.a2z.com/components/awsui-table/?example=common-table
 * @constructor
 */
export function ServerSideTableV3(props: ServerSideTableV3I) {

    const tableMetadata = props.tableMetadata;
    const userTablePreference = new UserTablePreferences(props.tableType)
    const [pageSize, setPageSize] = useState<number>(props.esQueryTableFilter.size)
    const currentPageIndexInitial = (Math.floor(props.esQueryTableFilter.from / pageSize)) + 1
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(currentPageIndexInitial)

    const [esQueryFilter, setEsQueryFilter] = useState<EsQueryTableFilterI>(props.esQueryTableFilter);
    const [selectedItems, setSelectedItems] = useState<Array<SMSWOUnifiedDashboardServiceLambda.Types.Details>>([]);
    const [columnDefinitions, setColumnDefinitions] = useState<ReadonlyArray<TableProps.ColumnDefinition<SMSWOUnifiedDashboardServiceLambda.Types.Details>>>(columnDefinitionsV3(tableMetadata))

    const {
        items,
        loading,
        totalCount,
        error
    } = useApiRequestV3(esQueryFilter, tableMetadata, props.tableType);
    const pagesCount: number = getPagesCount(totalCount, pageSize)

    useEffect(() => {
        setEsQueryFilter(props.esQueryTableFilter)
        UrlHelper.setUrlParamsFromTableFilters(props.esQueryTableFilter)
    }, [tableMetadata]);

    // When user sort by an column, we update state and URL.
    const onSortingChange = (event: any) => {
        const sortingOptions: TableProps.SortingState<KeyValuePairString> = {
            isDescending: event.detail.isDescending, sortingColumn: {
                sortingField: event.detail.sortingColumn.sortingField
            }
        }
        const esQueryFilterWithSorting = {
            ...esQueryFilter,
            sortingOptions
        }
        setEsQueryFilter(esQueryFilterWithSorting)
        setSelectedItems([])
        UrlHelper.setUrlParamsFromTableFilters(esQueryFilterWithSorting)
    };

    // When user goes to next page, updating state and url
    function handlePaginationChange(index: number) {
        setCurrentPageIndex(index)
        const updatedEsQueryFilter = {
            ...esQueryFilter,
            from: (index - 1) * pageSize,
        }
        setEsQueryFilter(updatedEsQueryFilter)
        setSelectedItems([])
        UrlHelper.setUrlParamsFromTableFilters(updatedEsQueryFilter)
    }

    // When user submitted primary and secondary filter.
    function handleSubmitAttributeFilterItems(attributeFilterItems: Array<AttributeFilterItemsI>) {
        const updatedEsQueryFilter = {
            ...esQueryFilter,
            attributeFilterItems,
            from: 0
        }
        setEsQueryFilter(updatedEsQueryFilter)
        setCurrentPageIndex(1)
        setSelectedItems([])
        UrlHelper.setUrlParamsFromTableFilters(updatedEsQueryFilter)
    }

    // When table preference change, updating local store preference and updating url.
    function handlePreferenceChange(detail: CollectionPreferencesProps.Preferences) {
        const visibleColumns = detail.visibleContent || DEFAULT_TABLE_PREFERENCE.visibleColumns
        if ((detail.pageSize != userTablePreference.getTablePreference().size) && (detail.pageSize != undefined)) {
            const updatedEsQueryFilter: EsQueryTableFilterI = {
                ...esQueryFilter,
                size: detail.pageSize,
                from: 0
            }
            setEsQueryFilter(updatedEsQueryFilter)
            setCurrentPageIndex(1)
            setPageSize(detail.pageSize)
            setSelectedItems([])
            UrlHelper.setUrlParamsFromTableFilters(updatedEsQueryFilter)
        }

        userTablePreference.setTablePreference({
            visibleColumns: [...visibleColumns],
            wrapLines: detail.wrapLines == undefined ? DEFAULT_TABLE_PREFERENCE.wrapLines : detail.wrapLines,
            size: detail.pageSize == undefined ? DEFAULT_TABLE_PREFERENCE.size : detail.pageSize
        })
        setColumnDefinitions(columnDefinitionsV3(tableMetadata))
    }

    return (
        <>
            <Alert type={"error"} visible={error != ""}> {error}</Alert>
            <Filters
                tableType={props.tableType} loading={loading} tableMetadata={tableMetadata}
                onSubmitAttributeFilterItems={handleSubmitAttributeFilterItems}
                attributeFilterItems={esQueryFilter.attributeFilterItems}
                hideSecondaryFilter={props.hideSecondaryFilter}
                hidePrimaryFilter={props.hidePrimaryFilter}
            />

            <Table
                loading={loading}
                selectedItems={selectedItems}
                items={items}
                onSortingChange={onSortingChange}
                onSelectionChange={event => setSelectedItems(event.detail.selectedItems)}
                sortingColumn={esQueryFilter.sortingOptions?.sortingColumn}
                sortingDescending={esQueryFilter.sortingOptions?.isDescending}
                columnDefinitions={columnDefinitions}
                visibleColumns={getVisibleColumns(tableMetadata, props.tableType)}
                selectionType="multi"
                stickyHeader={true}
                resizableColumns={true}
                wrapLines={userTablePreference.getTablePreference().wrapLines || false}
                header={
                    <ServerSideTableHeader tableType={props.tableType}
                                           selectedItems={selectedItems}
                                           totalItems={items}
                                           updateTools={null}
                                           serverSide={true}
                                           visible={false}
                                           tableMetadata={tableMetadata}
                                           handleRefresh={() => {
                                           }}
                                           totalItemCount={totalCount}
                                           title={""}
                                           description={""}
                                           actionButtons={""}
                    />
                }
                loadingText="Loading ..."
                empty={<TableNoMatchState/>}
                pagination={
                    <Pagination
                        pagesCount={pagesCount}
                        currentPageIndex={currentPageIndex}
                        disabled={loading}
                        onNextPageClick={(e) => handlePaginationChange(e.detail.requestedPageIndex)}
                        onPreviousPageClick={(e) => handlePaginationChange(e.detail.requestedPageIndex)}
                        onChange={(e) => handlePaginationChange(e.detail.currentPageIndex)}
                    />
                }
                preferences={<Preferences disabled={false}
                                          tableType={props.tableType}
                                          tableMetadata={tableMetadata}
                                          handlePreferenceChange={handlePreferenceChange}
                                          visibleContentOptions={[{
                                              label: "Columns list",
                                              options: getVisibleContentOptions(tableMetadata, props.tableType)
                                          }]}/>}
            />

        </>
    );
}


