import React, {useEffect, useState} from "react";
import {Badge, Box, Button, Container, ExpandableSection, Header, Spinner} from "@amzn/awsui-components-react-v3";
import {AwsStepFnUtil} from "src/model/aws-step-fn-util";
import {ListExecutionsOutput} from "aws-sdk/clients/stepfunctions";
import {toast} from "react-toastify";
import {PromiseManager} from "src/model/promise-manager";
import {AlertConfigI, AlertedItemsI} from "src/interfaces/promise-manager-interface";
import Moment from "moment";
import {BoxProps} from "@amzn/awsui-components-react-v3/polaris/box/interfaces";

export function PromiseDetailsContent() {

    const [sfnExecutions, setSfnExecutions] = useState<ListExecutionsOutput>({
        executions: []
    })

    const [loadingSfnExecutions, setLoadingSfnExecutions] = useState<boolean>(false)
    const [loadingAlerts, setLoadingAlerts] = useState<boolean>(false)
    const [startingNewExecutions, setStartingNewExecutions] = useState<boolean>(false)
    const [alertedItems, setAlertedItems] = useState<Array<AlertedItemsI>>([])

    const alertState: string = "ALERT"

    async function handleStartPromiseButtonClick() {
        setStartingNewExecutions(true)

        new AwsStepFnUtil().statSFNExecution()
            .then((resp) => {
                toast.success(`Started new SFN Execution ${resp.executionArn}`, {
                    autoClose: 10000
                })

                new PromiseManager().monitorWf(resp.executionArn)
                    .then(() => {
                        toast.success(`Started monitoring for ${resp.executionArn}`, {
                            autoClose: 10000
                        })
                    })

                // retrieveSfnExecutions();
            })
            .catch(err => {
                toast.error("Error while starting new SFN execution")
                console.error(err)
            })
            .then(() => {
                setStartingNewExecutions(false)
            });
    }

    async function retrieveSfnExecutions() {
        setLoadingSfnExecutions(true)
        setLoadingAlerts(true)

        await new AwsStepFnUtil().getAllSFNExecutions()
            .then((resp) => {
                setSfnExecutions(resp)
                console.log(resp.executions)
            })
            .catch(err => {
                toast.error("Error while listing step fn executions")
                console.log("Error while listing step fn executions", err)
            })
            .then(() => {
                setLoadingSfnExecutions(false)
            });

        new PromiseManager().getWFAlerts()
            .then((resp) => {
                console.log("getWFAlerts resp")
                console.log(resp.data.alertedItems)
                setAlertedItems(resp.data.alertedItems)
                resp.data.alertedItems.forEach(alertedItem => {
                    const config = JSON.parse(alertedItem.configuration)
                    console.log("config", config)
                })
            })
            .catch(() => {
                toast.error("Error while retrieving alerts")
            })
            .then(() => {
                setLoadingAlerts(false)
            })
    }

    useEffect(() => {
        const adding60Sec = Moment().add(60, 'seconds')
        console.log("adding60Sec", adding60Sec.toISOString())
        retrieveSfnExecutions()

    }, [])
    return <> <Header variant={"h1"} actions={<Button variant={"primary"} onClick={handleStartPromiseButtonClick}
                                                      loading={startingNewExecutions}>
        Start workflow
    </Button>}>Promise Manager</Header>

        <Box padding={{top: "l"}}>

            <Header
                variant="h2"
            >
                Alerts
            </Header>

            <>
                {loadingAlerts ? <Spinner size={"large"}/> :
                    <>{alertedItems.map(alertedItem => {
                        const configParsed = JSON.parse(alertedItem.configuration) as Array<AlertConfigI>
                        const isAlert: boolean = alertedItem.status == alertState
                        return <Box padding={{bottom: "l"}}> <Container
                            header={
                                <Header
                                    variant="h2"
                                >
                                    WorkflowId: {alertedItem.workflowInstanceId}
                                    <Badge color={isAlert ? "red" : "blue"}>{alertedItem.status}</Badge>
                                </Header>
                            }
                            key={alertedItem.workflowInstanceId}>

                            <table title={alertedItem.workflowInstanceId}
                                   className={"table table-bordered border-primary"}>
                                <thead>
                                <tr>
                                    <th className={"text-center"}>Stage</th>
                                    <th className={"text-center"}>Promise Threshold(In seconds)</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {configParsed.map((alertConfig, alertIndex) => {
                                    return <>{alertConfig.predictionThresholds.map((predictionThresholdsTemp, predictionThresholdsIndex) => {
                                        const isRed: boolean = (isAlert && alertIndex == 1)
                                        const textColor: BoxProps.Color = isRed ? "text-status-error" : "inherit"
                                        // const thresholdTextColor: BoxProps.Color = (isRed && predictionThresholdsIndex == 0 && predictionThresholdsTemp.thresholdInSec == 65) ? "text-status-error" : "inherit"
                                        const thresholdTextColor: BoxProps.Color = (isRed && (predictionThresholdsTemp.thresholdInSec == 65 || predictionThresholdsTemp.thresholdInSec == 35)) ? "text-status-error" : "inherit"
                                        return <tr key={`${alertIndex}-${predictionThresholdsIndex}`}>
                                            {predictionThresholdsIndex == 0 &&
                                            <td rowSpan={alertConfig.predictionThresholds.length} align={"center"}>
                                                <Box variant={"p"}
                                                     color={textColor}>
                                                    {alertConfig.stage}
                                                </Box>
                                            </td>
                                            }

                                            <td align={"center"}>
                                                <Box variant={"p"}
                                                    // color={(alertIndex == 1 && predictionThresholdsIndex == 0) ? "text-status-error" : "inherit"}>
                                                     color={thresholdTextColor}>
                                                    {predictionThresholdsTemp.thresholdInSec}
                                                </Box>
                                            </td>
                                            <td>
                                                <>
                                                    {predictionThresholdsTemp.action.emailOwners != undefined &&
                                                    <ExpandableSection header={
                                                        <Box color={textColor} variant={"p"}>
                                                            Email
                                                        </Box>}>
                                                        <ol>
                                                            {predictionThresholdsTemp.action.emailOwners.map(mailOwners => {
                                                                return <Box color={textColor} variant={"p"}>
                                                                    <li key={mailOwners}>{mailOwners}</li>
                                                                </Box>
                                                            })}
                                                        </ol>
                                                    </ExpandableSection>
                                                    }

                                                    {predictionThresholdsTemp.action.sim != undefined &&
                                                    <ExpandableSection header={"SIM/Ticket"}>
                                                        Category: {predictionThresholdsTemp.action.sim.category}
                                                        Item: {predictionThresholdsTemp.action.sim.item}
                                                        Resolver
                                                        group: {predictionThresholdsTemp.action.sim.resolverGroup}

                                                    </ExpandableSection>

                                                    }
                                                </>
                                            </td>

                                        </tr>

                                    })}
                                    </>
                                })}
                                    </tbody>

                                    </table>
                                    </Container>
                                    </Box>
                                })}</>
                    }
                    </>

                    </Box>

                {/*<Box padding={{top: "l"}}>*/}
                {/*    <Container*/}
                {/*        header={*/}
                {/*            <Header*/}
                {/*                variant="h2"*/}
                {/*            >*/}
                {/*                Recent Executions*/}
                {/*            </Header>*/}
                {/*        }*/}
                {/*    >*/}
                {/*        <ol>*/}
                {/*            {loadingSfnExecutions ? <Spinner size={"large"}/> :*/}
                {/*                <>{sfnExecutions.executions.map(sfnExecution => {*/}
                {/*                    return <li*/}
                {/*                        key={sfnExecution.executionArn}>{sfnExecution.executionArn} | {sfnExecution.status}</li>*/}
                {/*                })}</>*/}
                {/*            }*/}
                {/*        </ol>*/}
                {/*    </Container>*/}
                {/*</Box>*/}

                    </>
                }