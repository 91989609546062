import Select from "@amzn/awsui-components-react/polaris/select";
import {ISubmitEvent} from "@rjsf/core";
import {JSONSchema7} from "json-schema";
import {SelectDropdownOptions} from "src/interfaces/attribute-filters";

export const TABLE_PAGE_SIZE = "TABLE_PAGE_SIZE"
export const TABLE_METADATA = "TABLE_METADATA"
export const TABLE_FILTERING_TOKENS = "TABLE_FILTERING_TOKENS"

export const TARGET_WEBSITES: Array<string> = ["www.amazon.ae", "www.amazon.ca", "www.amazon.cn", "www.amazon.co.jp",
    "www.amazon.co.uk", "www.amazon.com", "www.amazon.com.au", "www.amazon.com.br", "www.amazon.com.mx", "www.amazon.com.tr", "www.amazon.de",
    "www.amazon.eg", "www.amazon.es", "www.amazon.fr", "www.amazon.in", "www.amazon.it", "www.amazon.nl",
    "www.amazon.pl", "www.amazon.sa", "www.amazon.se", "www.amazon.sg"]

export const PROGRAMS: Array<string> = ["ACBP", "OTS", "TEST"]

//TODO: Need to be updated.
export const WORKFLOW_STATUS_OPTIONS: Array<string> = []
//TODO: Need to be updated.
export const SCRAPE_STATUS_OPTIONS: Array<string> = ["Scrape_Completed", "Hold_Re_Crawl", "Scrape_Stage", "Hold_WF_Issue", "SSP_Stage", "Hold_Infeasible", "Scrape_CR"]
export const VARIANT_CONFIG_OPTIONS: Array<string> = ["Variant", "Non-Variant"]
export const CRAWL_ROUTE_TYPE_OPTIONS: Array<string> = ["EC2", "DSL"]

export const SELECT_DROPDOWN_ALL_PLACEHOLDER = "[ALL]"

export const EXECUTION_STAGES_LIST: Array<Select.IOption | Select.Option | Select.OptionsGroup> = [
    {id: "crawl", label: "Crawl"},
    {id: "scraperPlanner", label: "Scrape Planner"},
    {id: "sporc", label: "Sporc"}
]

export const EXECUTION_ID: string = 'workflowId'
export const WORKFLOW_START_DATE: string = 'workflowStartDate'
export const ATTEMPT_ID: string = 'recipeExecutionAttemptId'
export const USER_COMMENTS: string = 'userComments'
export const VARIANT_CONFIG: string = 'variantConfig'
export const CRAWL_ROUTE_TYPE: string = 'crawlRouteType'
export const TARGETED_WEBSITE: string = 'targetedWebsite'
export const WORKFLOW_STATUS: string = 'workFlowStatus'
export const PROGRAM: string = 'program'
export const SCRAPER_STATUS: string = 'scraperStatus'


export const EXECUTION_LIST_DROPDOWN_OPTIONS: SelectDropdownOptions = {
    [VARIANT_CONFIG]: VARIANT_CONFIG_OPTIONS,
    [CRAWL_ROUTE_TYPE]: CRAWL_ROUTE_TYPE_OPTIONS,
    [TARGETED_WEBSITE]: TARGET_WEBSITES,
    [PROGRAM]: PROGRAMS,
    [WORKFLOW_STATUS]: WORKFLOW_STATUS_OPTIONS,
    [SCRAPER_STATUS]: SCRAPE_STATUS_OPTIONS
}

export const ExecutionQuickOverview = {
    header: "Quick overview"
}
export const ExecutionsListTable = {
    header: "Execution List"
}
export const ExecutionsSummary = {
    header: "Execution Summary"
}

export const SWO_ORCHESTRATOR_SERVICE_CLIENT_ID: string = "SM"
