import React, {useEffect, useState} from 'react';
import {SelectedItemsInterface} from "src/interfaces/domain-tracker";
import Button from "@amzn/awsui-components-react/polaris/button";
import ButtonV3 from "@amzn/awsui-components-react-v3/polaris/button";
import Modal from "@amzn/awsui-components-react-v3/polaris/modal";
import {UpdateDelayAlert} from "src/components/common/user-alerts"
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {DomainTracker} from "src/model/DomainTracker";
import {TableProgressBar, useProgressBar} from "src/components/common/table-progress-bar";
import {ATTEMPT_ID, EXECUTION_ID, SWO_ORCHESTRATOR_SERVICE_CLIENT_ID} from "src/constants/domain-tracker";
import {StopRecipeExecutionResult} from "@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda";
import {getUserAlias} from "src/utils/cookie-helper";
import {v1 as uuidv1} from 'uuid';
import {Alert, Box, FormField, Input, SpaceBetween} from "@amzn/awsui-components-react-v3";
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";
import {logger} from "src/utils/logger";

/**
 * Terminate(stops) execution.
 * @param props
 * @constructor
 */
export function TerminateExecution(props: SelectedItemsInterface) {
    const [selectedItems, setSelectedItems] = useState<Array<SMSWOUnifiedDashboardServiceLambda.Types.Details>>(props.selectedItems);
    const [terminateReason, setTerminateReason] = useState<string>("")
    const [showTerminateModal, setShowTerminateModal] = useState<boolean>(false);
    const [bulkUpdatePromises, setBulkUpdatePromises] = useState<Array<Promise<any>>>([])
    const [progressBarVisible, setProgressBarVisible] = useState<boolean>(false)
    const toastSuccessMessage: React.ReactNode = <>Terminated successfully. <UpdateDelayAlert
        feature={"Workflow status"}
        plainText={true}/></>;
    const {
        progressPercentage,
        progressLoading,
        progressStatus,
        isCompleted,
        isCompletedSuccessfully,
        errorMessages
    } = useProgressBar(bulkUpdatePromises, toastSuccessMessage);


    useEffect(() => {
        setSelectedItems(props.selectedItems)
        setProgressBarVisible(false)
    }, [props.selectedItems, showTerminateModal])

    useEffect(() => {
        if (isCompletedSuccessfully) {
            setShowTerminateModal(false)
        }
    }, [isCompletedSuccessfully])


    function handleTerminateSubmitButton(): void {
        if (terminateReason.trim() == "") {
            setTerminateReason("")
            return
        }
        logger.info(`Terminating ${selectedItems.length} workflow instances`, selectedItems.map(selectedItem => selectedItem[EXECUTION_ID]))
        const domainTracker = new DomainTracker();
        setProgressBarVisible(true);
        SwoUiMetrics.publishCloudWatchCountMetric(props.selectedItems.length > 1 ? metricFeaturesList.bulkTerminateWorkflowInstance : metricFeaturesList.terminateWorkflowInstance, metricPageNames.workflowInstanceListView)
        const terminateRequestPromises: Array<Promise<StopRecipeExecutionResult>> = selectedItems.map(selectedItem => {
            return domainTracker.terminateExecution(SWO_ORCHESTRATOR_SERVICE_CLIENT_ID, selectedItem[EXECUTION_ID],
                uuidv1(), getUserAlias(), terminateReason)
        })
        setBulkUpdatePromises(terminateRequestPromises);
    }

    return <>
        <Box variant={"div"} data-testid={"terminate-execution-modal-container"}>
            <Modal
                id={"terminate-execution-modal"}
                data-testid={"terminate-execution-modal"}
                onDismiss={() => setShowTerminateModal(false)}
                visible={showTerminateModal}
                size="medium"
                header={`Terminate Execution`}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <ButtonV3 variant="link" loading={progressLoading} id={"terminate-execution-cancel-button"}
                                      onClick={() => setShowTerminateModal(false)}>Cancel</ButtonV3>
                            <ButtonV3 variant="primary" loading={progressLoading}
                                      id={"terminate-execution-submit-button"}
                                      onClick={handleTerminateSubmitButton}>Terminate</ButtonV3>
                        </SpaceBetween>
                    </Box>
                }
            >
                <FormField label="Execution id(s)">
                    <Box variant={"pre"}>
                        <ol>
                            {props.selectedItems.map(selectedItem => {
                                const executionId = selectedItem[ATTEMPT_ID] || selectedItem[EXECUTION_ID]
                                return <li key={executionId}>{executionId}</li>
                            })}
                        </ol>
                    </Box>
                </FormField>
                <FormField
                    id={"terminate-execution-reason-form-field"}
                    label="Reason for termination"
                    errorText={terminateReason == "" ? `Please provide reason for termination` : null}
                >
                    <Input value={terminateReason} onChange={e => setTerminateReason(e.detail.value)}/>
                </FormField>
                <Box padding={{top: "l"}}>
                    <Alert type={"warning"}>Terminate {props.selectedItems.length} execution(s)? This action cannot be
                        reversed.</Alert>
                </Box>
                <br/>

                {((progressLoading || isCompleted) && progressBarVisible) &&
                <Box variant={"div"}>
                    <TableProgressBar progressStatus={progressStatus}
                                      progressPercentage={progressPercentage}
                                      totalRecordLength={selectedItems.length}
                                      title={`Terminating ${selectedItems.length} execution(s)`}
                                      errorMessages={errorMessages}
                    />
                </Box>
                }
            </Modal>
        </Box>
        <Button id={"terminate-execution-button"}
                onClick={() => setShowTerminateModal(true)}
                disabled={props.selectedItems.length == 0}
        >
            Terminate
        </Button>

    </>
}