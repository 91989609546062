import React, {useEffect} from "react";
import {AppRoutes, BreadcrumbGroupWithRouter} from "src/components/common/router-common";
import {ServiceNavigation} from "src/components/navigation";
import {appLayoutNavigationLabels} from "src/common/labels";
import AppLayout from "@amzn/awsui-components-react-v3/polaris/app-layout";
import {ExecutionSummaryContent} from "src/components/execution-summary/execution-summary-content";
import "src/styles/workflow-summary.scss"
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";
/**
 * Aggregate Execution Summary view
 * @constructor
 */
export function ExecutionSummary() {
    const breadcrumbItems = [
        {
            "text": "Home",
            "href": "/"
        },
        {
            "text": "Execution summary",
            "href": AppRoutes.executionList
        }
    ]

    useEffect(() => {
        SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.index, metricPageNames.workflowAggregateInstanceView)
    })

    return <>
        <AppLayout
            navigation={<ServiceNavigation/>}
            content={<ExecutionSummaryContent/>}
            breadcrumbs={<BreadcrumbGroupWithRouter items={breadcrumbItems}/>}
            disableContentPaddings={false}
            toolsHide={true}
            ariaLabels={appLayoutNavigationLabels}
        />
    </>
}
