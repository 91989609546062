import React, {useEffect, useState} from 'react';
import {WorkflowInstanceRestartConfigOverrideI} from "src/interfaces/domain-tracker";
import {DomainTracker} from "src/model/DomainTracker";
import {JSONSchema7} from "json-schema";
import {toast} from "react-toastify";
import {Alert, Checkbox, Spinner} from "@amzn/awsui-components-react";
import {GetRecipeExecutionDetailsResult} from "@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda";
import Form, {ISubmitEvent} from "@rjsf/core";
import {ATTEMPT_ID, EXECUTION_ID} from "src/constants/domain-tracker";
import {UpdateDelayAlert} from "src/components/common/user-alerts";

/**
 * Create JSON schema based form to provide input while restarting from any stage.
 * @param props
 * @constructor
 */
export function ExecutionRestartInputOverride(props: WorkflowInstanceRestartConfigOverrideI) {
    const [enableInputOverride, setEnableInputOverride] = useState<boolean>(false);
    const [userInputJsonSchema, setUserInputJsonSchema] = useState<JSONSchema7 | undefined>({})
    const [userInputFormData, setUserInputFormData] = useState<any>(undefined)
    const [loading, setLoading] = useState<boolean>(false)
    const selectedItem = props.selectedItems[0]
    const isMoreThanOneRecordSelected = props.selectedItems.length > 1


    function validateSchema(recipeExecutionDetailsResult: GetRecipeExecutionDetailsResult) {
        if ((recipeExecutionDetailsResult.recipeExecutionInputSchema != undefined) && (recipeExecutionDetailsResult.recipeExecutionInputSchema != "")) {
            try {
                const jsonSchemaParsed = JSON.parse(recipeExecutionDetailsResult.recipeExecutionInputSchema)
                setUserInputJsonSchema(jsonSchemaParsed)
            } catch (error) {
                toast.error("Error while parsing execution input schema");
                setUserInputJsonSchema(undefined)
                console.error(error);
            }
        }
    }

    function validateInput(recipeExecutionDetailsResult: GetRecipeExecutionDetailsResult) {
        if ((recipeExecutionDetailsResult.recipeExecutionInput != undefined) && (recipeExecutionDetailsResult.recipeExecutionInput != "")) {
            try {
                setUserInputFormData(JSON.parse(recipeExecutionDetailsResult.recipeExecutionInput))
            } catch (error) {
                toast.error("Error while parsing execution input");
                setUserInputFormData(undefined)
                console.error(error);
            }
        }
    }

    function retrieveJSONSchema() {
        setLoading(true)
        const domainTracker = new DomainTracker();
        const workflowInstanceId = selectedItem[ATTEMPT_ID] || selectedItem[EXECUTION_ID]
        const workflowInstance = domainTracker.getWorkflowInstance(workflowInstanceId)
        workflowInstance.then((recipeExecutionDetailsResult) => {
            validateSchema(recipeExecutionDetailsResult)
            validateInput(recipeExecutionDetailsResult)
        }).catch((error) => {
            toast.error("Failed to retrieve execution details");
            setEnableInputOverride(false)
            setUserInputFormData(undefined)
            setUserInputJsonSchema(undefined)
        }).then(() => {
            setLoading(false)
        })
    }

    function handleFormSubmit(e: ISubmitEvent<any>) {
        setUserInputFormData(e.formData)
        props.onRestartSubmitButton(e, enableInputOverride)
    }

    function handleFormChange(e: ISubmitEvent<any>) {
        setUserInputFormData(e.formData)
    }

    function handleEnableInputOverrideChange(e: CustomEvent<Checkbox.ChangeDetail>) {
        const checked: boolean = e.detail.checked
        setEnableInputOverride(checked)
        setUserInputFormData(undefined)
        setUserInputJsonSchema({})
        if (checked) retrieveJSONSchema();
    }

    useEffect(() => {
        setEnableInputOverride(false)
    }, [props.selectedItems, props.modalVisible])


    return <>
        {isMoreThanOneRecordSelected ? <Alert className={"awsui-util-pb-l"}
                                              data-testid={"execution-restart-input-override-not-supported-alert"}
            >
                <b>Input override</b> feature is not supported for bulk restart.</Alert>
            :
            <Checkbox className={"awsui-util-pb-l"} checked={enableInputOverride}
                      data-testid={"execution-restart-input-override-checkbox"}
                      onChange={handleEnableInputOverrideChange} disabled={loading}>
                Override input?
            </Checkbox>
        }

        {loading ? <><Spinner size={"big"}/> Loading execution details</> :
            <>
                {userInputJsonSchema == undefined &&
                <Alert>Override input is not available for this workflow instance</Alert>
                }
            </>
        }

        <div className={"col-xxs-12"}>
            <Form schema={enableInputOverride && userInputJsonSchema || {}}
                  formData={enableInputOverride ? userInputFormData : undefined}
                  onSubmit={handleFormSubmit}
                  onChange={handleFormChange}
                  className={"col-xxs-12"}
                  showErrorList={enableInputOverride}
                  data-testid={"execution-restart-json-schema-form"}
            >
                <div className={"col-xxs-12 awsui-util-pb-l"}><UpdateDelayAlert feature={"Workflow status"}
                                                                                plainText={false}/></div>
                <button ref={props.submitFormRef} type="submit" style={{display: "none"}}/>

            </Form>

        </div>


    </>
}