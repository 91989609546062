import React, {useEffect} from "react";
import {AppRoutes, BreadcrumbGroupWithRouter} from "src/components/common/router-common";
import {ServiceNavigation} from "src/components/navigation";
import {appLayoutNavigationLabels} from "src/common/labels";
import AppLayout from "@amzn/awsui-components-react-v3/polaris/app-layout";
import {ExecutionSummaryContent} from "src/components/execution-summary/execution-summary-content";
import "src/styles/workflow-summary.scss"
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";
import {PromiseDetailsContent} from "src/components/promise-details/promise-details-content";
/**
 * Aggregate Execution Summary view
 * @constructor
 */
export function PromiseDetails() {
    const breadcrumbItems = [
        {
            "text": "Home",
            "href": "/"
        },
        {
            "text": "Promise manager",
            "href": "#"
        }
    ]


    return <>
        <AppLayout
            navigation={<ServiceNavigation/>}
            content={<PromiseDetailsContent />}
            breadcrumbs={<BreadcrumbGroupWithRouter items={breadcrumbItems}/>}
            disableContentPaddings={false}
            toolsHide={true}
            ariaLabels={appLayoutNavigationLabels}
        />
    </>
}
