import React, {useState} from "react";
import {Button, Container, Header, Input, Textarea} from "@amzn/awsui-components-react-v3";
import {InputProps} from "@amzn/awsui-components-react-v3/polaris/input/interfaces";
import {DomainTracker} from "src/model/DomainTracker";
import {v4 as uuidv4} from 'uuid';
import {SWO_ORCHESTRATOR_SERVICE_CLIENT_ID} from "src/constants/domain-tracker";
import {RECIPES_NAMES} from "src/constants/orchestractor-service";
import {AwsUtils} from "src/model/aws-utils";

export function BulkOnboardingContent() {

    const [inputJson, setInputJson] = useState<string>("")
    const [localFilePath, setLocalFilePath] = useState<FileList | null>()
    const [localFileName, setLocalFileName] = useState<string>("")
    const [fileBlob, setFileBlob] = useState<Blob>(new Blob())

    function handleChange(e: InputProps.ChangeDetail) {
        console.log("Textarea change", e.value)
        setInputJson(e.value);

    }

    function handleSubmit() {
        if (fileBlob.size == 0) {
            alert("Please select a file")
            return false;
        }


        const dt = new DomainTracker()
        const localFilePathString = localFilePath?.item(0)?.name

        const obj = {hello: 'world'};
        const blob = new Blob([JSON.stringify(obj, null, 2)], {type : 'application/json'});


        new AwsUtils().s3Upload("sam-dev-us-west-2-aravip-199711326154", "uploadTest", fileBlob, localFileName)
        // dt.bulkStartWorkflows(localFilePath?.item(0)?.name || "", SWO_ORCHESTRATOR_SERVICE_CLIENT_ID, RECIPES_NAMES.ACBP_RECIPE, uuidv4() )
        //     .then((resp) => {
        //         console.log("startExecution resp success", resp)
        //     })
        //     .catch(error => {
        //         console.log("startExecution resp error", error)
        //     });

    }

    return <>

        <Container
            header={
                <Header
                    variant="h2"
                    description="Container description"
                >
                    Bulk start workflows
                </Header>
            }
        >
            Container content
            <input type={"file"} onChange={(e) => {
                if ((e.target.files != undefined) && (e.target.files.length > 0)) {
                    console.log("Not undefined")
                    setFileBlob(e.target.files[0])
                    setLocalFileName(e.target.files[0].name)
                    console.log("e.target.files[0].type: ", e.target.files[0].type)
                }

                setLocalFilePath(e.target.files)
            }} />
            Local file path {JSON.stringify(localFilePath?.item(0)?.name || "")}
            <Button onClick={handleSubmit} variant={"primary"}>Submit</Button>

        </Container>


        Bulk onboarding content

    </>
}