import React from "react";
import {AppRoutes, BreadcrumbGroupWithRouter, RouteLabels} from "src/components/common/router-common";
import AppLayout from "@amzn/awsui-components-react-v3/polaris/app-layout";
import {ServiceNavigation} from "src/components/navigation";
import {ReleaseNotesContent} from "src/components/release-notes/content";
import {appLayoutNavigationLabels} from "src/common/labels";
import {BulkOnboardingContent} from "src/components/bulk-onboarding/content";
export function BulkOnboarding() {
    const bcItems = [
        {
            "text": "Home",
            "href": "/"
        },
        {
            "text": RouteLabels.bulkOnboarding,
            "href": AppRoutes.bulkOnboarding
        },

    ]

    return <AppLayout
        navigation={<ServiceNavigation/>}
        content={<BulkOnboardingContent />}
        contentType="default"
        disableContentPaddings={false}
        toolsHide={true}
        ariaLabels={appLayoutNavigationLabels}
        breadcrumbs={<BreadcrumbGroupWithRouter items={bcItems}/>}
    />
}