import AppLayout from '@amzn/awsui-components-react-v3/polaris/app-layout';
import React, {useEffect, useState} from 'react';
import {appLayoutNavigationLabels} from 'src/common/labels';
import {ServiceNavigation} from 'src/components/navigation';
import 'src/styles/index.scss';
import {AppRoutes, BreadcrumbGroupWithRouter} from "src/components/common/router-common";
import {RouteComponentProps} from "react-router-dom";
import {BreadcrumbGroupProps} from "@amzn/awsui-components-react-v3/polaris/breadcrumb-group/interfaces";
import {WorkflowDetailContent} from "src/components/execution-detail/workflow-detail-content";
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";

interface MatchParams {
    workflowId: string;
}

export default function ExecutionDetailPage(props: RouteComponentProps<MatchParams>) {
    const [navigationOpen, setNavigationOpen] = useState(false);
    const workflowId: string = props.match.params.workflowId

    useEffect(() => {
        SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.index, metricPageNames.workflowAttemptsView)
    })

    const bcItems: Array<BreadcrumbGroupProps.Item> = [
        {
            "text": "Home",
            "href": AppRoutes.home
        },
        {
            "text": "Executions",
            "href": AppRoutes.executionList
        },
        {
            "text": workflowId,
            "href": AppRoutes.executionDetail(workflowId)
        }
    ]


    return (
        <AppLayout
            navigation={<ServiceNavigation/>}
            content={<>
                {/*<WorkflowDetailContent workflowId={workflowId}/>*/}
                <WorkflowDetailContent workflowId={workflowId}/>
            </>}
            breadcrumbs={<BreadcrumbGroupWithRouter items={bcItems}/>}
            contentType="default"
            disableContentPaddings={false}
            navigationOpen={navigationOpen}
            onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
            toolsHide={true}
            ariaLabels={appLayoutNavigationLabels}
        />
    );
}