import SideNavigation, {SideNavigationProps} from "@amzn/awsui-components-react-v3/polaris/side-navigation";
import React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {AppRoutes, ExternalRoutes, RouteLabels} from "src/components/common/router-common";
import {isProd} from "src/utils/env";
// TODO: bring back the component
// import BreadcrumbGroup from '@amzn/awsui-components-react/polaris/breadcrumb-group';

const HOME = {text: "SWO", href: `#${AppRoutes.home}`};
const EXECUTION_LIST: SideNavigationProps.Item[] = [
    {type: "link", text: "Workflow Instance List", href: `#${AppRoutes.executionList}`},
]

const EXECUTION_SUMMARY: SideNavigationProps.Item[] = [
    {type: "link", text: "Aggregate Workflow Instance Summary", href: `#${AppRoutes.executionSummary}`}
]


const EXECUTION_SUMMARY_KIBANA: SideNavigationProps.Item[] = [
    {
        type: "link",
        text: RouteLabels.acbpAutomatedPipelineSummary,
        href: `${ExternalRoutes.acbpAutomatedPipelineSummary}`,
        external: true
    }
]

const EXECUTION_ITEMS: SideNavigationProps.Item[] = [...EXECUTION_LIST, ...EXECUTION_SUMMARY]
if (isProd()) {
    EXECUTION_ITEMS.push(...EXECUTION_SUMMARY_KIBANA)
}


const EXECUTION_SECTION: SideNavigationProps.Item[] = [{
    type: "section", text: "Execution",
    items: EXECUTION_ITEMS
}]

const WORKITEM_E2E: SideNavigationProps.Item[] = [
    {
        type: "link",
        text: RouteLabels.e2ePipelineWorkitemSummary,
        href: `${ExternalRoutes.e2ePipelineWorkitemSummary}`,
        external: true
    }
]

const WORKITEM_SECTION: SideNavigationProps.Item[] = [{
    type: "section", text: "Workitem",
    items: [...WORKITEM_E2E]
}]

const ONBOARDING_SECTION: SideNavigationProps.Item[] = [{
    type: "section", text: "Onboarding",
    items: [{
        type: "link",
        text: RouteLabels.bulkOnboarding,
        href: `#${AppRoutes.bulkOnboarding}`
    }]
}]

const items: SideNavigationProps.Item[] = [{type: "link", text: "Home", href: `#${AppRoutes.home}`},
    ...EXECUTION_SECTION
];

if (isProd()) {
    items.push(...WORKITEM_SECTION)
}

items.push(...ONBOARDING_SECTION)


const CYCLE_TIME_SECTION: SideNavigationProps.Item[] = [{
    type: "section", text: "Cycle time",
    items: [{
        type: "link",
        text: RouteLabels.stageLevelCycleTimeDashboard,
        href: ExternalRoutes.stageLevelCycleTimeDashboard,
        external: true
    }, {
        type: "link",
        text: RouteLabels.subStageLevelCycleTimeDashboard,
        href: ExternalRoutes.subStageLevelCycleTimeDashboard,
        external: true
    }]
}]

if (isProd()) {
    items.push(...CYCLE_TIME_SECTION)
}


const VIRTUAL_PIPELINE_LIST: SideNavigationProps.Item[] = [
    { type: "link", text: "Virtual Pipeline List", href: `#${AppRoutes.virtualPipelines}` }
]

const VIRTUAL_PIPELINE_SECTION: SideNavigationProps.Item[] = [{
    type: "section", text: "Virtual Pipeline",
    items: VIRTUAL_PIPELINE_LIST
}]

items.push(...VIRTUAL_PIPELINE_SECTION)

items.push({
    type: "divider"
})

items.push({ type: "link", text: "Promise manager", href: `#/promise-manager` })


items.push({
    type: "divider"
}, {
    type: "link", text: RouteLabels.releaseNotes, href: `#${AppRoutes.releaseNotes}`
})

// Class ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
class ServiceNavigationLocal extends React.Component<RouteComponentProps> {
    // If the provided link is empty, do not redirect pages
    onFollowHandler(event: CustomEvent<SideNavigationProps.FollowDetail>) {
        event.preventDefault();
        if (event.detail.external) {
            window.open(event.detail.href, "_blank")
            return;
        }
        
        this.props.history.push(event.detail.href.substring(1));
    }

    render() {
        return (
            <SideNavigation
                header={HOME}
                items={items}
                activeHref={`#${this.props.location.pathname}`}
                onFollow={this.onFollowHandler.bind(this)}
            />
        );
    }
}

export const ServiceNavigation = withRouter(ServiceNavigationLocal);
