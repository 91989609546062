import AWS from 'aws-sdk';
import {v4 as uuidv4} from 'uuid';

export class AwsUtils {
    async s3Upload(bucketName: string, path: string, fileBlob: Blob, fileName: string) {
        const bucket = new AWS.S3({
            params: {
                Bucket: bucketName
            }
        })

        bucket.putObject({
            Body: fileBlob, Bucket: "sam-dev-us-west-2-aravip-199711326154", Key: `uploadTest/${uuidv4()}_${fileName}`,
        }).promise()
    }
}